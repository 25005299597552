.image-container {
    padding: 7px;
    text-align: center;
}

.image-container .expense-image {
    width: 90%;
}

.expense-actions {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
    margin-bottom: 15px;
}

.expense-container {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
    /* border-radius: 25px;
    border: 1px solid #22358B; */
}

.section-title {
    margin-top: 5px;
    text-decoration: underline;
}

.label {
    font-size: 13px;
    margin-bottom: 0px;
}

.filter-container {
    margin-top: 5px;
    margin-bottom: 15px;
}

.filter-row {
    margin-bottom: 15px;
}

.status {
    font-weight: 600;
    text-align: center;
}

.orange {
    color: orange;
}

.red {
    color: red;
}

.green {
    color: green;
}