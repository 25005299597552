.driver-container {
    padding: 10px;
    margin-top: 10px;
    margin-bottom: 15px;
}

.actions {
    margin-top: 15px;
    margin-bottom: 15px;
}

.btn-override {
    margin: 5px;
}

.points {
    /* text-decoration: underline; */
    font-weight: 600;
}

.label {
    font-size: 13px;
    margin-bottom: 0px;
}

.section-title {
    text-decoration: underline;
}

.top-page-actions {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 5px;
}

.top-page-actions > * {
    margin-right: 10px;
}