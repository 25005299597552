table.sticky-first th:first-child, table.sticky-first td:first-child
{
  position:sticky;
  left:0px;
  background-color:white;
}

table.sticky-first thead:first-child {
  position: sticky;
  top: 0;
  background-color: white;
  z-index: 1;
}