.verification-container {
 text-align: center; 
}

.address-information {
  margin: 0px;
  font-size: 14px;
}

.table-responsive
{
  max-height: 822px;
  overflow-y: auto;
  overflow-x: auto;
}