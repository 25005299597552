.list-group-item{
    display: flex;
    align-items: center;
    padding: 1.25rem 1.25rem;
}

.list-group-item-text
{
    flex-grow: 2;
    text-align: end;
}

.table th{
    border-top: none;
}

.list-group-item
{
    padding: 0.5rem 1.25rem;
}

.list-group-item.active{
    background-color: #22358b;
    color: white;
}

