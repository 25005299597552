.dropdownButton {
  margin-top: 0px !important;
  padding: 0px !important;
  border: 0px;
}

.dropdown-menu {
  margin: 2px !important;
  padding: 0px !important;
}

.dropdown-item {
  padding: 5px;
  color: #0056b3;
  font-weight: 500;
}

.dropdown a a {
  padding: 0.25rem 1rem;
  width: 100%;
}

.nav-item {
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
}

.nav-link-header {
  color: #fff !important;
}

.dropdown-top-level {
  color: #ffffff;
  padding: 0.5rem 1rem;
  border-radius: 0.25rem;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
  margin: 0 1rem;
}

.dropdown {
  color: #ffffff;
  padding: 0.5rem 0rem;
  border-radius: 0.25rem;
  text-decoration: none;
  transition: background-color 0.2s ease-in-out;
}

.dropdown-top-level:hover,
.dropdown-top-level:focus {
  background-color: #0056b3;
  color: #ffffff;
  text-decoration: none;
}

.dropdown-item a,
.dropdown-item a:hover,
.dropdown-item a:focus {
  color: inherit;
  text-decoration: none;
}
