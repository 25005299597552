.status-container {
    flex: 1;
}

.swing-container {
    padding: 30px;
}

.input-note {
    font-size: 12px;
    color: grey;
    text-align: right;
    margin-bottom: 0;
  }

.address-container {
    margin-bottom: 10px;
}

.view-job-container {
    padding-left: 10px;
    padding-right: 10px;
}

.bottom-container {
    display: flex;
    align-items: flex-end;
}

.payment-status-container {
    text-align: center;
    margin-bottom: 12px;
}


.status-container {
    text-align: center;
}

.team-leader {
    color: orange;
    text-decoration: underline;
    text-decoration-color: orange;
}

table td, .table th {
    vertical-align: middle !important;
}

.swing-action-container {
    margin-top: 10px;
    flex: 1 1;
    justify-content: flex-start;
}

.swing-action-container .btn-primary {
    margin: 7px
}

.job-status-container {
    text-align: center;
    padding-left: 5px;
}

.job-update-status {
    font-weight: 600;
    font-size: 13px;
}

.view-job-container .dropdown-menu.show {
    min-width: 50vw;
    background-color: rgba(155, 155, 155, 0.90);
    padding: 5%;
    padding-left: 10%;
    padding-right: 10%;
}


.view-job-container .dropdown-item:hover {
    background-color: rgba(155, 155, 155, 0.9);
    cursor: default;
}

.toggle-btn {
    margin: 1px;
}
.showing-results-bottom-container {
    display: flex;
    align-items: flex-end;
}
.showing-results-text {
    font-size: 10px;
    text-align: center;
}