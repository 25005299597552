.vehicle-container {
    padding: 20px;
    width: 100%;
}

.vehicle-row {
    width: 100%;
    margin: 15px;
}

.list-row {
    padding-bottom: 7px;
    border-bottom: 1px solid #22358B;
}

.status-container {
    flex: 1;
}

.swing-container {
    padding: 30px;
}

.columns-card {
    display: inline-block;
    width: 100%;
}

.odometer-text {
    font-size: 1.5em;
    font-weight: 500;
    text-decoration: underline;
}
.vehicle-move-card {
    width: 100%;
    padding: 2%
}

.image-container {
    padding: 7px;
    text-align: center;
}

.image-container .vehicle-image {
    width: 90%;
}