.available-title {
    font-size: 25px;
}

.drivers-container {
    padding: 20px;
}

.driver-count {
    font-size: 12px;
    text-decoration: underline;
    text-align: center;
    margin-bottom: 0;
}

.badge {
    margin: 1px;
}

.reset-right {
    padding-bottom: 15px;
    justify-content: flex-end;
}