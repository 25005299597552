.actions {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-evenly;
  margin-bottom: 15px;
}

.section-title {
  text-decoration: underline;
}

.label {
  font-size: 13px;
  margin-bottom: 0px;
  text-decoration: underline;
}

.status-value {
  font-weight: 600;
  margin-bottom: 0;
  font-size: 15px;
}
.status {
  font-weight: 600;
  text-align: center;
}

.status-value {
  font-weight: 600;
}

.admin-notes {
  text-align: right;
}

.action-container {
  margin-top: 10px;
  margin-bottom: 15px;
  display: flex;
  justify-content: space-around;
}

.orange {
  color: orange;
}

.red {
  color: red;
}

.green {
  color: green;
}

.selected {
  font-size: 1.5em;
  text-decoration: underline;
  font-weight: 500;
}

.answers-container {
  width: 100%;
  text-align: center;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  flex-direction: row;
}

.questionnaire-container {
  background-color: rgba(176, 176, 176, 0.3);
  margin: 2px;
  margin-top: 20px;
  padding: 20px;
  border-radius: 5px;
}

.alert-warning {
  text-align: center;
  font-weight: 500;
  padding: 20px;
}

.driver-comment-container {
  margin-bottom: 15px;
}

.driver-comment-container p {
  margin-bottom: 0px;
}